import React from "react";
import section1Image from "../assets/images/CHgoteroLarga.webp";
import section2Image from "../assets/images/cajaGotero.webp";
import section3Image from "../assets/images/goteros.webp";
import section4Image from "../assets/images/escritorioSesion.webp";
import moonIcon from "../assets/icons/moon-icon.svg";
import starIcon from "../assets/icons/star-icon.svg";
import hojaIcon from "../assets/icons/hoja-icon.svg";
import ListItem from "../components/ListItem";
import Section from "../components/Section";
import SectionReverse from "../components/SectionReverse";

const TerapiaFloral = () => {
  return (
    <div>
      <Section
        title="La terapia floral es para vos si…"
        imageUrl={section1Image}
      >
        <ul className="flex flex-col gap-2 text-primary">
          <ListItem
            icon={moonIcon}
            content="Buscas una forma natural de gestionar tus emociones."
          />
          <ListItem
            icon={moonIcon}
            content="Sentís un desborde emocional y no sabés qué hacer."
          />
          <ListItem
            icon={moonIcon}
            content="Estás buscando conectar con tu vocación."
          />
          <ListItem
            icon={moonIcon}
            content="Querés conectar con tu intuición, pero no sabes cómo."
          />
          <ListItem icon={moonIcon} content="Tenés muchas inseguridades." />
          <ListItem icon={moonIcon} content="No sabes lo que te gusta." />
          <ListItem icon={moonIcon} content="Tenés baja autoestima." />
          <ListItem icon={moonIcon} content="Sentís que no estás en armonía." />
          <ListItem icon={moonIcon} content="Querés conocerte más." />
          <ListItem
            icon={moonIcon}
            content="Tenés la disposición para trabajar en tu crecimiento personal."
          />
        </ul>
      </Section>

      <SectionReverse
        title="En las sesiones de terapia floral y aromaterapia vas a conseguir…"
        imageUrl={section2Image}
      >
        <ul className="flex flex-col gap-2">
          <ListItem
            icon={starIcon}
            content="Más claridad sobre lo que te pasa."
          />
          <ListItem icon={starIcon} content="Mayor autoconocimiento." />
          <ListItem
            icon={starIcon}
            content="Aprender a gestionar tus emociones sin evadirlas."
          />
          <ListItem
            icon={starIcon}
            content="Aprender a aceptar tus procesos."
          />
          <ListItem
            icon={starIcon}
            content="Aprender a escucharte cada vez más."
          />
          <ListItem
            icon={starIcon}
            content="Sentir una mayor conexión con vos."
          />
          <ListItem
            icon={starIcon}
            content="Potenciar el equilibrio energético con aromas específicos que refuercen tu proceso."
          />
        </ul>
      </SectionReverse>

      <Section
        title="¿Qué es la terapia floral y cómo se complementa con la aromaterapia?"
        imageUrl={section3Image}
      >
        <ul className="flex flex-col gap-2">
          <ListItem
            icon={hojaIcon}
            content="La terapia floral utiliza esencias de flores para trabajar a
                nivel energético, influyendo en los planos psíquico, emocional y
                físico. Estas esencias ayudan a restablecer el equilibrio
                interno, armonizando los chakras y facilitando un estado de
                bienestar general."
          />

          <ListItem
            icon={hojaIcon}
            content="La forma de trabajo que tienen es a través de la toma de
                conciencia, ayudándonos a descubrir aspectos propios que no
                estaban a nuestro alcance para poder ser resueltos."
          />
          <ListItem
            icon={hojaIcon}
            content="Trabajo con cuatro sistemas florales: Bach, California, Saint
                Germain y Bush, cada uno con beneficios únicos, que ayudan a
                abordar temas como ansiedad, miedos, baja autoestima, estrés,
                motivación y muchísimo más!."
          />
          <ListItem
            icon={hojaIcon}
            content="La ventaja de trabajar con estos cuatro sistemas es que podemos
                abordar de manera más completa las necesidades emocionales,
                adaptándonos a cada situación específica con una variedad más
                amplia de esencias."
          />
          <ListItem
            icon={hojaIcon}
            content="Ahora, además de tu fórmula floral, podés potenciar tu proceso con aromaterapia. Si elegís la opción con gotero, recibirás también un spray áurico con aceites esenciales seleccionados según lo que necesites trabajar."
          />
          <ListItem
            icon={hojaIcon}
            content="Los aromas específicos refuerzan el trabajo energético de las flores, ayudando a integrar emociones, liberar tensiones y generar un estado de calma y bienestar."
          />
          <ListItem
            icon={hojaIcon}
            content="Si ya probaste las flores de Bach, trabajar con estos cuatro
                sistemas te va a encantar!"
          />
        </ul>
      </Section>
      <section className="bg-purple-800 p-4 text-white">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="order-2 md:order-1">
            <img src={section4Image} alt="logo" className="rounded-xl" />
          </div>
          <div className="place-self-center order-1 md:order-2">
            <h2 className="text-3xl font-medium mb-6 text-center">
              Acerca de la sesión
            </h2>
            <ul className="flex flex-col gap-2 text-lg  mb-4">
              <li>Duración: Hasta 60 minutos por Google Meets.</li>
              <li>
                Post-sesión: Entrega de un Journal para tomar mayor conciencia
                del proceso con las flores.
              </li>
              <li>
                Seguimiento: Siempre tendrás la posibilidad de contarme cómo te
                está yendo con el gotero después de la sesión.
              </li>
              <li>
                Tipos de sesión:
                <li>
                  Con gotero y spray áurico personalizado: Lo podés pasar a
                  retirar por Banfield o recibirlo por e-Pick (envío no
                  incluido)
                </li>
                <li>
                  Sin gotero: Te paso la fórmula floral para que te lo prepares
                  en cualquier farmacia homeopática.
                </li>
              </li>
              <li>Los goteros se toman durante 15 días.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="bg-purple-200 p-4 md:p-6">
        <h2 className="text-3xl font-medium mb-6 text-center">
          Formas de pago:
        </h2>
        <p className="flex flex-col gap-2 text-lg mb-4 text-center">
          Se puede abonar por mercadopago o transferencia bancaria.
        </p>
        <p className="flex flex-col gap-2 text-lg mb-4 text-center">
          Se puede pagar la totalidad antes de la sesión o dejar una seña del
          50% (en caso de ser sesiones con gotero) de lo contrario se debe
          abonar la totalidad antes de la sesión.
        </p>
        <p className="flex flex-col gap-2 text-lg mb-4 text-center">
          En caso de cancelación de turno se requiere avisar con al menos 24
          horas de anticipación.
        </p>
      </section>
      <section className="bg-purple-800 text-white p-4 md:p-6">
        <div className="container mx-auto place-self-center text-center">
          <h2 className="text-3xl font-medium mb-6">
            🌿 Elegí la terapia floral y la aromaterapia para acompañar tu
            bienestar.
          </h2>
          <p className="flex flex-col gap-2 text-lg mb-4">
            Elegí volver a vos🌹.
          </p>
          <a
            target="_blank"
            href="https://lospiesenlaluna.empretienda.com.ar/"
            className="bg-white text-purple-800 py-4 px-6 rounded-xl inline-block font-medium"
            rel="noreferrer"
          >
            ¡Quiero empezar!
          </a>
        </div>
      </section>
    </div>
  );
};

export default TerapiaFloral;
