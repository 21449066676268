import React from "react";
import section1Image from "../assets/images/manosFlores.jpg";
import section5Image from "../assets/images/lotosAgua.jpg";
import section4Image from "../assets/images/velaCuaderno.jpg";
import section3mage from "../assets/images/cristales.jpg";
import section2mage from "../assets/images/manoFlorBlancaA.jpg";
import hojaIcon from "../assets/icons/hoja-icon.svg";

const ListItem = ({ text, icon }) => {
  return (
    <li className="flex gap-2">
      <img className="w-4" src={hojaIcon} alt="icon" />
      <p>{text}</p>
    </li>
  );
};

const Reiki = () => {
  return (
    <div>
      <section className="bg-purple-200 p-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="place-self-center">
            <h2 className="text-3xl mb-6 font-medium text-primary">
              El Reiki es para vos si…
            </h2>
            <ul className="flex flex-col gap-2 text-lg text-primary">
              <ListItem
                text="Sentís que tu energía está estancada o desbalanceada."
                icon={hojaIcon}
              />
              <ListItem
                text="Estás atravesando momentos de estrés, ansiedad o cansancio."
                icon={hojaIcon}
              />
              <ListItem
                text="Querés acompañar tus procesos emocionales desde lo energético."
                icon={hojaIcon}
              />
              <ListItem
                text="Buscás un momento para relajarte y conectar con vos."
                icon={hojaIcon}
              />
              <ListItem
                text="Sentís tensión en el cuerpo o te cuesta soltar preocupaciones."
                icon={hojaIcon}
              />
              <ListItem
                text="Querés explorar una herramienta que trabaje con la energía y el bienestar integral."
                icon={hojaIcon}
              />
            </ul>
          </div>
          <div>
            <img src={section1Image} alt="Reiki Usui" className="rounded-xl" />
          </div>
        </div>
      </section>

      <section className="bg-purple-800 p-4 text-white">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="order-2 md:order-1">
            <img
              src={section5Image}
              alt="Reiki Kundalini"
              className="rounded-xl"
            />
          </div>
          <div className="place-self-center order-1 md:order-2">
            <h2 className="text-3xl font-medium mb-6">
              En las sesiones de Reiki podés…
            </h2>
            <ul className="flex flex-col gap-2 text-lg">
              <ListItem
                text="Relajarte y encontrar un momento de calma."
                icon={hojaIcon}
              />
              <ListItem
                text="Sentir una mayor conexión con tu cuerpo y emociones."
                icon={hojaIcon}
              />
              <ListItem
                text="Favorecer el equilibrio energético."
                icon={hojaIcon}
              />
              <ListItem
                text="Permitir que la energía empiece a moverse de manera más fluida."
                icon={hojaIcon}
              />
              <ListItem
                text="Acompañar tus procesos internos desde un enfoque sutil pero profundo.

"
                icon={hojaIcon}
              />
            </ul>
          </div>
        </div>
      </section>

      <section className="bg-purple-200 p-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="place-self-center">
            <h2 className="text-3xl font-medium mb-6 text-center text-primary">
              ¿Qué es el Reiki?
            </h2>
            <ul className="flex flex-col gap-2 text-lg text-primary">
              <ListItem
                text="El Reiki es una terapia energética en la que canalizo y transmito energía universal a través de mis manos. No busca reemplazar ningún tratamiento médico ni generar cambios inmediatos, sino acompañar el proceso energético de cada persona."
                icon={hojaIcon}
              />
              <h2 className="text-3xl font-medium mb-6 text-center text-primary">
                Trabajo con dos sistemas:
              </h2>
              <ListItem
                text="Reiki Usui: Un método tradicional japonés que ayuda a armonizar el cuerpo, la mente y las emociones."
                icon={hojaIcon}
              />
              <ListItem
                text="Reiki Kundalini: Un enfoque que limpia y desbloquea los canales energéticos, favoreciendo una mayor conexión con la propia energía."
                icon={hojaIcon}
              />
              <ListItem
                text="El Reiki puede aplicarse tanto de forma presencial como a distancia, ya que la energía no tiene límites físicos y llega donde es necesaria."
                icon={hojaIcon}
              />
            </ul>
          </div>
          <div>
            <img
              src={section3mage}
              alt="Canalización de Chakras"
              className="rounded-xl"
            />
          </div>
        </div>
      </section>

      <section className="bg-purple-800 text-white p-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="order-2 md:order-1">
            <img
              src={section4Image}
              alt="Canalización de Chakras"
              className="rounded-xl"
            />
          </div>
          <div className="place-self-center order-1 md:order-2">
            <h2 className="text-3xl font-medium mb-6 text-center">
              Canalización de los chakras con Registros Akáshicos
            </h2>
            <ul className="flex flex-col gap-2 text-lg">
              <ListItem
                text="Además de la sesión de Reiki, también canalizo la información de tus chakras a través de la apertura de tus Registros Akáshicos. Durante la sesión recibo información sobre el estado de cada centro energético y posibles bloqueos o aspectos a trabajar.🔮 "
                icon={hojaIcon}
              />
              <ListItem
                text="Entrega: Luego de la sesión, recibirás un PDF con la información canalizada para que puedas comprender mejor tu energía y acompañar tu propio proceso. 📄 "
                icon={hojaIcon}
              />
            </ul>
          </div>
        </div>
      </section>
      <section id="integracion-reiki" className="bg-purple-200 p-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="order-1 md:order-2">
            <img
              src={section2mage}
              alt="Canalización de Chakras"
              className="rounded-xl"
            />
          </div>
          <div className="place-self-center order-2 md:order-1">
            <h2 className="text-3xl font-medium mb-6 text-center text-primary">
              Acerca de la sesión
            </h2>

            <ul className="flex flex-col gap-2 text-lg text-primary">
              <ListItem
                text="Duración:60 minutos a distancia.⏳"
                icon={hojaIcon}
              />
              <ListItem
                text="Post-sesión: mensajes del oráculo 🔮 y el PDF con la canalización de tus chakras (si solicitas esta opción). "
                icon={hojaIcon}
              />
              <ListItem
                text=" Seguimiento 🔄: Si lo necesitás, podés contarme cómo te sentís
                en los días posteriores."
                icon={hojaIcon}
              />
            </ul>
          </div>
        </div>
      </section>
      <section className="bg-purple-800 text-white p-4 md:p-6">
        <div className="container mx-auto place-self-center text-center">
          <h2 className="text-3xl font-medium mb-6">
            Elegí un espacio para conectar con tu energía y bienestar
          </h2>
          <p className="flex flex-col gap-2 text-lg mb-4">
            Regalate un momento para vos o para alguien que querés. Soltá
            tensiones, desbloqueá tu energía y permitite conectar con más
            armonía y claridad.
          </p>
          <a
            target="_blank"
            href="https://lospiesenlaluna.empretienda.com.ar/"
            className="bg-white text-purple-800 py-4 px-6 rounded-xl inline-block font-medium"
            rel="noreferrer"
          >
            ¡Quiero empezar!
          </a>
        </div>
      </section>
    </div>
  );
};
export default Reiki;
