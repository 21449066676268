import React from "react";
import gatoFlores from "../assets/images/gatoNaranjaCH.webp";
import perroFlores from "../assets/images/perroFlores.webp";
import gatoFlores2 from "../assets/images/goteroAncho.webp";
import escritorioSesion from "../assets/images/escritorioSesion.webp";
import petIcon from "../assets/icons/pet-icon.svg";
import dogIcon from "../assets/icons/dog-icon.svg";
import catIcon from "../assets/icons/cat-icon.svg";

const ListItem = ({ text }) => {
  return (
    <li className="flex gap-2">
      <img className="w-4" src={petIcon} alt={"pet"} />
      <p>{text}</p>
    </li>
  );
};
const ListItem2 = ({ text }) => {
  return (
    <li className="flex gap-2">
      <img className="w-4" src={dogIcon} alt={"pet"} />
      <p>{text}</p>
    </li>
  );
};

const ListItem3 = ({ text }) => {
  return (
    <li className="flex gap-2">
      <img className="w-4" src={catIcon} alt={"pet"} />
      <p>{text}</p>
    </li>
  );
};
const SobreMi = () => {
  return (
    <div>
      <section className="bg-purple-200 p-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="place-self-center">
            <h2 className="text-3xl mb-6 font-medium text-primary">
              La terapia floral y el Reiki pueden ayudar a tu mascota si…
            </h2>
            <ul className="flex flex-col gap-2 text-lg text-primary">
              <ListItem text="Tu mascota está estresada o ansiosa." />
              <ListItem text="Notas cambios de comportamiento recientes." />
              <ListItem text="Está pasando por una adaptación difícil, como una mudanza o la llegada de un nuevo miembro de la familia." />
              <ListItem text="Tiene muchos miedos." />
              <ListItem text="Buscás un tratamiento complementario para mejorar el bienestar de tu mascota." />
            </ul>
          </div>
          <div>
            <img
              src={gatoFlores}
              alt="terapia floral para mascotas"
              className="h-screen w-full object-cover rounded-xl"
            />
          </div>
        </div>
      </section>

      <section className="bg-purple-800 p-4 text-white">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="order-2 md:order-1">
            <img
              src={gatoFlores2}
              alt="terapia floral para mascotas"
              className="rounded-xl"
            />
          </div>
          <div className="place-self-center order-1 md:order-2">
            <h2 className="text-3xl  mb-6 font-medium">
              ¿Qué beneficios puede obtener tu mascota?
            </h2>
            <div>
              <h2 className="text-3xl  mb-6 font-medium">
                🌿 Con terapia floral:
              </h2>
              <ul className="flex flex-col gap-2 text-lg">
                <ListItem text="Mejora en su comportamiento y adaptación a cambios." />
                <ListItem text="Fortalecimiento de la conexión emocional entre vos y tu mascota." />
                <ListItem text="Apoyo en la recuperación de traumas o experiencias negativas." />
                <ListItem text="Fomento del bienestar general y equilibrio emocional." />
              </ul>
            </div>
            <div className="my-4">
              <h2 className="text-3xl  mb-6 font-medium">✨ Con Reiki:</h2>
              <ul className="flex flex-col gap-2 text-lg">
                <ListItem text="Armonización energética para aliviar tensiones físicas y emocionales." />
                <ListItem text="Mayor relajación y sensación de calma." />
                <ListItem text="Acompañamiento en procesos de enfermedad o recuperación." />
                <ListItem text="Mejora del estado de ánimo y vitalidad." />
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-purple-200 p-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="place-self-center order-2 md:order-1">
            <h2 className="text-3xl mb-6 font-medium text-primary">
              ¿Cómo funcionan?
            </h2>
            <h2 className="text-3xl mb-6 font-medium text-primary">
              🌸 Terapia Floral para Mascotas:
            </h2>
            <ul className="flex flex-col gap-2 text-lg text-primary">
              <ListItem2
                text="Al igual que en humanos, la terapia floral en mascotas se enfoca
              en equilibrar el estado emocional de los animales. Se utilizan
              esencias florales que actúan a nivel energético, ayudando a
              aliviar el estrés, el miedo, la agresividad, entre otros problemas
              emocionales."
              />

              <ListItem3
                text="Cada fórmula floral se prepara de manera personalizada, teniendo
              en cuenta las necesidades específicas de tu mascota, para mejorar
              su bienestar y calidad de vida."
              />
            </ul>
            <div className="my-4">
              <h2 className="text-3xl mb-6 font-medium text-primary">
                👐 Reiki para Mascotas:
              </h2>
              <ul className="flex flex-col gap-2 text-lg text-primary">
                <ListItem2 text="Se canaliza energía a través de la imposición de manos para armonizar el sistema energético del animal." />

                <ListItem3 text="Ayuda a liberar bloqueos y mejorar el bienestar general." />
                <ListItem2 text="no es invasiva, se realiza a distancia, permitiendo que la mascota reciba la energía en su entorno habitual, sin necesidad de contacto físico." />
              </ul>
            </div>
          </div>
          <div className="order-2 md:order-1">
            <img src={perroFlores} alt="logo" className="rounded-xl" />
          </div>
        </div>
      </section>

      <section className="bg-purple-800 p-4 text-white">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="order-2 md:order-1">
            <img src={escritorioSesion} alt="logo" className="rounded-xl" />
          </div>
          <div lassName="place-self-center order-2 md:order-1">
            <h2 className="text-3xl font-medium mb-6 text-center">
              Acerca de la sesión para mascotas
            </h2>
            <ul className="flex flex-col gap-2 text-lg  mb-4">
              <li>Duración: Hasta 60 minutos (se realiza online).</li>
              <li>
                Post-sesión: Seguimiento para ajustar el tratamiento según la
                evolución de la mascota.
              </li>
              <li>
                Tipos de sesión:
                <ul>
                  <li>
                    Terapia floral con gotero: Lo podés pasar a retirar o
                    recibirlo por envío (envío no incluido).
                  </li>
                  <li>
                    Terapia floral sin gotero: Te paso la fórmula floral para
                    que la prepares en cualquier farmacia homeopática.
                  </li>
                  <li>
                    Reiki para mascotas: Sesión de armonización energética a
                    distancia.
                  </li>
                </ul>
              </li>
              <li>Los goteros se administran a la mascota durante 15 días.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="bg-purple-200 p-4 text-primary">
        <h2 className="text-3xl font-medium mb-6 text-center">
          Formas de pago:
        </h2>
        <p className="flex flex-col gap-2 text-lg mb-4 text-center">
          Se puede abonar por mercadopago o transferencia bancaria.
        </p>
        <p className="flex flex-col gap-2 text-lg mb-4 text-center">
          Se puede pagar la totalidad antes de la sesión o dejar una seña del
          50% (en caso de ser sesiones con gotero) de lo contrario se debe
          abonar la totalidad antes de la sesión.
        </p>
        <p className="flex flex-col gap-2 text-lg mb-4 text-center">
          En caso de cancelación de turno se requiere avisar con al menos 24
          horas de anticipación.
        </p>
      </section>
      <section className="bg-purple-800 p-4 text-white">
        <div className="container mx-auto place-self-center text-center">
          <h2 className="text-3xl font-medium mb-6">
            ¡Ayudá a tu mascota a sentirse mejor! 🐶🐱
          </h2>
          <p className="flex flex-col gap-2 text-lg mb-4">
            Una terapia complementaria y natural para su bienestar.
          </p>
          <a
            target="_blank"
            href="https://lospiesenlaluna.empretienda.com.ar/"
            className="bg-white text-purple-800 py-4 px-6 rounded-xl inline-block font-medium"
            rel="noreferrer"
            // Cuando tengas la tienda, cambia el href al enlace del producto
          >
            ¡Quiero empezar!
          </a>
        </div>
      </section>
    </div>
  );
};

export default SobreMi;
